import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./App.css";
import "animate.css";

const DefaultLayout = React.lazy(() => import("./DefaultLayout"));
const PrivacyPolicy = React.lazy(() =>
  import("./modules/privacy-policy/PrivacyPolicy"),
);
const TermsAndCondition = React.lazy(() =>
  import("./modules/terms-and-condition/TermsAndCondition"),
);
const BookingDetails = React.lazy(() =>
  import("./modules/booking-details/BookingDetails"),
);
const DeleteUserData = React.lazy(() =>
  import(`./modules/delete-user-data/DeleteUserData`),
);
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Suspense>
          <Routes>
            <Route path="/" element={<DefaultLayout />} />
            <Route exact path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-and-condition" element={<TermsAndCondition />} />
            <Route path="delete-user-data" element={<DeleteUserData />} />
            <Route path="booking-details/:id" element={<BookingDetails />} />
            <Route index element={<DefaultLayout />} />
            <Route path="*" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
